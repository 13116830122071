import React from "react";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { IModalMessage, IModalMessageType } from "../../Shared/Interfaces";
import { MainContainer } from "./styles";
import package_json from "./../../../package.json";
interface IModalMessageProps {
  modalMessage: IModalMessage;
  setModalMessage: (value: IModalMessage) => void;
}

export function ModalMessage({
  modalMessage,
  setModalMessage,
}: IModalMessageProps) {
  return (
    <>
      {modalMessage.show && (
        <MainContainer>
          <ToastContainer
            className="p-3"
            position={"middle-center"}
            style={{ zIndex: "5" }}
            id="toast-container"
          >
            <Toast
              onClose={() => setModalMessage({ ...modalMessage, show: false })}
              show={modalMessage.show}
              bg={modalMessage.type}
              style={{ width: "500px" }}
            >
              <Toast.Header closeButton={false} style={{ background: "white" }}>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">
                  Semalo - {package_json.version}
                </strong>
              </Toast.Header>
              <Toast.Body>
                <div
                  style={{
                    fontSize: "18px",
                    color:
                      modalMessage.type === IModalMessageType.danger
                        ? "white"
                        : "black",
                  }}
                >
                  {modalMessage.text}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="warning"
                    onClick={() =>
                      setModalMessage({ ...modalMessage, show: false })
                    }
                  >
                    {" "}
                    Fechar{" "}
                  </Button>
                </div>
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </MainContainer>
      )}
    </>
  );
}
