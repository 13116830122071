import styled from "styled-components";
import { Card } from "react-bootstrap";
interface ProductListContainerProps {
  show: boolean;
}

export const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  background: #1a2139;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

export const ContainerContent = styled.div`
  flex: 5;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const BoldLabel = styled.span`
  font-weight: 700;
  margin-right: 5px;
`;

export const BoldLabelTitle = styled.span`
  font-weight: 700;
  margin-right: 5px;

  @media (max-width: 768px) {
    max-width: 100%; /* Ajuste conforme necessário */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 3; */
    overflow: hidden;
    word-wrap: break-word;
  }
`;

export const StyledCard = styled(Card)`
  width: 220px;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    /* width: 185px; */
    width: auto;
    /* max-width: 100%; */
  }
`;

export const ProductListContainer = styled.div<ProductListContainerProps>`
  width: 500px;
  overflow-y: auto;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-wrap: wrap;
  margin-left: 15px;
  margin-top: 15px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-left: 5px;
    margin-top: 5px;
  }
`;
export const ProductListItem = styled.div`
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  margin-right: 15px;

  @media (max-width: 768px) {
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    margin-right: 5px;
    max-width: 48.5%;
    /* background-color: red; */
  }
`;
