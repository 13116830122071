
import axios, { AxiosError } from "axios";
import { UserToken } from "../../Shared/user";

export const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        // Authorization: `Bearer ${UserToken.getInstance().getToken()}`,
        'Content-Type': 'application/json'
    }
});

api.interceptors.response.use(async (config) => {

    // console.log('localstoragetoken----@@#', localStorage.getItem('apontamento.jwt'));
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('apontamento.jwt')}`;
    return config;

}, (error: AxiosError) => {
    if (error?.response?.status === 401) {
        window.location.href = '/';
        console.log('error--', error);
    }
    return Promise.reject(error);
})