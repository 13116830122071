import React from "react";

import { Card } from "react-bootstrap";
import { INote } from "../../../Shared/Interfaces";
import { getLocalDate, getLocalTime } from "../../../utils/formatValue";
import { StyledCard } from "./styles";

interface IProps {
  notes: INote[];
}

export default function NotesHistory({ notes }: IProps) {
  return (
    <div>
      <StyledCard text={"dark"} bg={"Light"} className="mb-2">
        <Card.Body>
          <Card.Header>
            <span>Apontamentos Realizados</span>
          </Card.Header>
          <Card.Body>
            <table>
              <thead>
                <tr>
                  <th style={{ paddingRight: "5px" }}>Data</th>
                  <th style={{ paddingRight: "5px" }}>Usuário</th>
                  <th style={{ paddingRight: "5px" }}>Apontamento</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note, idx) => (
                  <tr key={idx}>
                    <td style={{ paddingRight: "5px" }}>
                      {getLocalDate(note?.DHAPO?.toString())}{" "}
                      {getLocalTime(note?.DHAPO)}
                    </td>
                    <td style={{ paddingRight: "5px" }}>{note.CODUSU}</td>
                    <td style={{ paddingRight: "5px" }}>{note.NUAPO}</td>
                    <td>{note.QTDEPROD}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card.Body>
      </StyledCard>
    </div>
  );
}
