import { Container, HeaderLogo, HeaderTitle } from "./styles";
import { MdLogout } from "react-icons/md";
import { useContext } from "react";
import { AuthContext } from "../../Shared/AuthContext";
import Countdown, { zeroPad } from "react-countdown";
import package_json from "./../../../package.json";
interface props {
  icon: any;
  label: string;
}

export const Header = ({ icon, label }: props) => {
  const { logoff } = useContext(AuthContext);

  return (
    <Container>
      <header>
        <HeaderLogo>
          <img src="logo.png" alt="logo" width={"100%"} />
        </HeaderLogo>
        <HeaderTitle>
          <div>{icon}</div>
          <span>{label}</span>
        </HeaderTitle>
        <div
          style={{
            height: "100%",
            margin: "10px",
            flexDirection: "column",
            display: "flex",
            marginRight: "20px",
          }}
        >
          <div style={{ marginTop: "8px" }}>
            <Countdown
              date={Date.now() + 30 * 10000}
              zeroPadTime={2}
              onComplete={logoff}
              renderer={(props) => (
                <span style={{ color: "white" }}>{`${props.minutes}:${zeroPad(
                  props.seconds,
                  2
                )}`}</span>
              )}
            />
          </div>
          <div style={{ flex: "1", marginTop: "16px" }}>
            <MdLogout
              size={24}
              color={"white"}
              onClick={logoff}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ flex: "1", marginTop: "15px", color: "white" }}>
            {package_json.version}
          </div>
        </div>
      </header>
    </Container>
  );
};
