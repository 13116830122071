import styled from "styled-components";
import { Card } from "react-bootstrap";

export const BoldLabel = styled.span`
  font-weight: 700;
  font-size: small;
  margin-right: 5px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

export const StyledCard = styled(Card)`
  width: 290px;
  margin: 5px;
  margin-left: 1px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 6px;
  }
`;
export const StyledCardProduct = styled(Card)`
  width: 380px;
  margin: 5px;
  margin-right: 0px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
