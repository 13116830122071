import styled from "styled-components";

export const MainContainer = styled.div`
   
    position: fixed; /* Positioning and size */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128,128,128,0.5); /* color */
    z-index: 4;

    div {
        z-index: 99;
    }
  
   
  `;