import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import { Products } from "../pages/Products";

export const AppRoutes = () =>
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/products" element={<Products />} />
  </Routes>
  ;
