import styled from "styled-components";
import { Card } from "react-bootstrap";

export const StyledCard = styled(Card)`
  width: 670px;
  margin: 5px;
  margin-right: 0px;
  height: 300px;
  overflow: scroll;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
